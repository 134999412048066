import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import InventoryIcon from "@mui/icons-material/Inventory";
import PeopleIcon from "@mui/icons-material/People";

import { db } from "../firebase";
import { collection, query, getDocs } from "firebase/firestore";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

// Registrar los componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

// Función para obtener los datos de usuarios por mes
const fetchUserDataByMonth = async (setTotalUsers) => {
  try {
    const usersRef = collection(db, "users");
    const q = query(usersRef);
    const querySnapshot = await getDocs(q);

    const monthlyData = Array(12).fill(0);
    let totalUsers = 0;

    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      const createdAt = userData.createdAt.toDate();
      const month = createdAt.getMonth();
      monthlyData[month] += 1;
      totalUsers += 1;
    });

    setTotalUsers(totalUsers);
    return monthlyData;
  } catch (error) {
    console.error("Error al obtener los datos de usuarios:", error);
    setTotalUsers(0);
    return Array(12).fill(0);
  }
};

// Función para obtener los datos de productos por mes
const fetchProductDataByMonth = async (setTotalProducts) => {
  try {
    const productsRef = collection(db, "productos");
    const q = query(productsRef);
    const querySnapshot = await getDocs(q);

    const monthlyData = Array(12).fill(0);
    let totalProducts = 0;

    querySnapshot.forEach((doc) => {
      const productData = doc.data();
      const createAt = productData.createAt.toDate();
      const month = createAt.getMonth();
      monthlyData[month] += 1;
      totalProducts += 1;
    });

    setTotalProducts(totalProducts);
    return monthlyData;
  } catch (error) {
    console.error("Error al obtener los datos de productos:", error);
    setTotalProducts(0);
    return Array(12).fill(0);
  }
};

const Home = () => {
  const [userData, setUserData] = useState(null);
  const [salesData, setSalesData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);

  useEffect(() => {
    const loadUserData = async () => {
      const userMonthlyData = await fetchUserDataByMonth(setTotalUsers);

      const monthLabels = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];

      const labelsWithValues = monthLabels.map(
        (month, index) => `${month} (${userMonthlyData[index]})`
      );

      setUserData({
        labels: labelsWithValues,
        datasets: [
          {
            label: "Usuarios",
            data: userMonthlyData,
            borderColor: "rgba(75,192,192,1)",
            backgroundColor: "rgba(75,192,192,0.2)",
            fill: true,
          },
        ],
      });
    };

    const loadProductData = async () => {
      const productMonthlyData = await fetchProductDataByMonth(
        setTotalProducts
      );

      const monthLabels = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];

      const labelsWithValues = monthLabels.map(
        (month, index) => `${month} (${productMonthlyData[index]})`
      );

      setProductData({
        labels: labelsWithValues,
        datasets: [
          {
            label: "Productos",
            data: productMonthlyData,
            borderColor: "rgba(153,102,255,1)",
            backgroundColor: "rgba(153,102,255,0.2)",
            fill: true,
          },
        ],
      });
    };

    loadUserData();
    loadProductData();

    setSalesData({
      labels: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      datasets: [
        {
          label: "Ventas",
          data: [50, 70, 60, 90, 80, 100, 0, 0, 0, 0, 0, 0],
          borderColor: "rgba(255,99,132,1)",
          backgroundColor: "rgba(255,99,132,0.2)",
          fill: true,
        },
      ],
    });
  }, []);

  return (
    <div style={{ padding: 20 }}>
      {/* <h1>Home</h1>
      <p>Bienvenido a la página principal</p> */}
      <h3>Año - {new Date().getFullYear()}</h3>
      <p>
        Total de Usuarios: <b>{totalUsers}</b>
      </p>
      <p>
        Total de Productos: <b>{totalProducts}</b>
      </p>
      {/* Mostrar total de productos */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          margin: 30,
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flex: "1 1 300px",
              minWidth: "300px",
            }}
          >
            <h5
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <PeopleIcon htmlColor="#555" /> Cantidad de Usuarios Total y por
              Mes
            </h5>
            {userData ? <Line data={userData} /> : <p>Cargando datos...</p>}
          </div>

          <div
            style={{
              flex: "1 1 300px",
              minWidth: "300px",
            }}
          >
            <h5
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <InventoryIcon htmlColor="#555" /> Cantidad de Productos Agregados
              por Mes
            </h5>
            {productData ? (
              <Bar data={productData} />
            ) : (
              <p>Cargando datos...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <div style={{ flex: 1 }}>
            <h5>Cantidad de Ventas Totales y por Mes</h5>
            {salesData ? <Line data={salesData} /> : <p>Cargando datos...</p>}
          </div> */
}

export default Home;
